import React from "react"

export const LoginHeader = () => (
  <div className="loginheader_div">
    <div className="loginheader">
      <div className="loginheaderlist_div">
        <img alt="icon doller" src="/sites/default/files/icon_doller.png" />
        <a rel="" href="/pricing/">
          Pricing
        </a>
      </div>
      <div className="loginheaderlist_div">
        <img alt="icon trial" src="/sites/default/files/icon_trial.png" />
        <a
          rel=""
          href="https://institution.happymangocredit.com/register/banker?sRegisterType=0"
          target="_blank"
        >
          Free Trial
        </a>
      </div>

      <div className="loginheaderlist_div">
        <img alt="user" src="/sites/default/files/user.png" />
        <span className="login_span">
          <a rel="" href="javascript:;">
            Log In
          </a>
          <ul className="login_ul_son">
            <li className="login_li_son">
              <a
                rel=""
                href="https://user.happymangocredit.com"
                target="_blank"
              >
                Individual/Business
              </a>
            </li>
            <li className="login_li_son">
              <a
                rel=""
                href="https://institution.happymangocredit.com"
                target="_blank"
              >
                Financial Institutions
              </a>
            </li>
            <li className="login_li_son">
              <a
                rel=""
                href="https://advisor.happymangocredit.com"
                target="_blank"
              >
                Financial Coaches
              </a>
            </li>
          </ul>
        </span>
      </div>
    </div>
  </div>
)

export default props => (
  <ul className="nav_ul">
    <li className="nav_li">
      <a rel="" href="/services/">
        Our Services
      </a>
      <ul className="nav_ul_son">
        <li className="nav_li_son">
          <a rel="" href="/services/">
            Digitalize Financial Services
          </a>
        </li>
        <li className="nav_li_son">
          <a rel="" href="">
            Customize Underwriting
          </a>
        </li>
        <li className="nav_li_son">
          <a rel="" href="">
            Expand Customer Base
          </a>
        </li>
        <li className="nav_li_son">
          <a rel="" href="">
            Improve Financial Health
          </a>
        </li>
        <li className="nav_li_son">
          <a rel="" href="">
            Quantify Program Impact
          </a>
        </li>
      </ul>
    </li>
    <li className="nav_li">
      <a rel="" href="/clients/">
        Our Clients
      </a>
      <ul className="nav_ul_son">
        <li className="nav_li_son">
          <a rel="" href="">
            Banks
          </a>
        </li>
        <li className="nav_li_son">
          <a rel="" href="">
            Credit Unions
          </a>
        </li>
        <li className="nav_li_son">
          <a rel="" href="">
            CDFIs
          </a>
        </li>
        <li className="nav_li_son">
          <a rel="" href="">
            Financial Coaches
          </a>
        </li>
      </ul>
    </li>
    <li className="nav_li">
      <a rel="" href="/why/">
        Why Happy Mango?
      </a>
    </li>
  </ul>
)
